<template>
  <TransitionRoot
    :show="isOpen"
    enter="transition-opacity ease-out duration-400"
    enter-from="opacity-0"
    enter-to="opacity-100"
    leave="transition-opacity ease-in duration-400"
    leave-from="opacity-100"
    leave-to="opacity-0"
    class="relative z-[950]"
  >
    <div
      class="bg-ablack flex lg:items-center lg:justify-center bg-opacity-30 fixed top-0 w-full h-full z-[950]"
    >
      <div
        class="flex flex-col justify-between h-auto fixed lg:relative bottom-0 left-0 w-full rounded-t-[15px] lg:rounded-[15px] lg:w-[594px] px-6 lg:px-12 pt-7 pb-12 lg:py-9 bg-white"
        v-click-away="onClickAway"
      >
        <IconComponent
          name="CloseIcon"
          class="h-[30px] w-[30px] absolute top-4 right-4 cursor-pointer fill-black"
          @click="close()"
        />
        <div class="flex flex-col"  v-show="!isSuccessful">
          <span class="text-xl font-bold leading-7 mb-6"
            >Получите подробное коммерческое предложение</span
          >
          <span class="text-xs leading-[17px] mb-5"
            >Оставьте заявку, и мы предоставим Вам полную информацию о франшизе и
            проконсультируем по всем вопросам</span
          >
          <Form
            v-slot="{ errors }"
            class="flex flex-col gap-5"
            @submit="onSubmit"
          >
            <div class="flex relative">
              <Field
                name="name"
                class="input-auth lg:!h-14 w-full lg:px-5 lg:py-4"
                :rules="nameRules"
                placeholder="Имя*"
                v-model="name"
                :class="{ 'input-error': errors.name }"
              />
              <ErrorMessage
                name="name"
                class="absolute top-[100%] pt-1 text-ared text-xs"
              />
            </div>
            <div class="flex relative">
              <Field
                name="phone"
                class="input-auth lg:!h-14 w-full"
                :rules="phoneRules"
                placeholder="+7 (___) ___-__-__*"
                v-maska:[options]
                v-model="phone"
                :class="{ 'input-error': errors.phone }"
              />
              <ErrorMessage
                name="phone"
                class="absolute top-[100%] pt-1 text-ared text-xs"
              />
            </div>
            <div class="flex relative">
              <Field
                name="email"
                class="input-auth lg:!h-14 w-full"
                :rules="emailRules"
                placeholder="E-mail"
                v-model="email"
                :class="{ 'input-error': errors.email }"
              />
              <ErrorMessage
                name="email"
                class="absolute top-[100%] pt-1 text-ared text-xs"
              />
            </div>
            <div class="flex relative">
              <Field
                name="city"
                class="input-auth lg:!h-14 w-full"
                type="text"
                placeholder="Город"
                v-model="city"
                :class="{ 'input-error': errors.city }"
              />
              <ErrorMessage
                name="city"
                class="absolute top-[100%] pt-1 text-ared text-xs"
              />
            </div>
            <button class="button mt-2">Отправить</button>
            <span
              >Отправляя заявку Вы соглашаетесь на
              <a
                class="underline"
                href="https://arbat.life/doc/document.pdf"
                target="_blank"
                >обработку персональных данных</a
              ></span
            >
          </Form>
        </div>
        <div class="flex flex-col gap-3 md:gap-5" v-if="isSuccessful">
          <span class="text-base md:text-3xl font-bold">Спасибо! Ваша заявка принята</span>
          <span class="text-sm md:text-2xl ">В ближайшее время с Вами свяжется наш менеджер по продаже франшизы.</span>
        </div>
      </div>
    </div>
  </TransitionRoot>
</template>

<script setup>
import { reactive, ref } from 'vue';
import { TransitionRoot } from '@headlessui/vue';
import { vMaska } from 'maska';
import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import useModals from '@/composables/useModals.js';
import IconComponent from '@/components/IconComponent.vue';

const onClickAway = () => close()

const { useModal } = useModals();
const { isOpen, close } = useModal('callBackFranchiseModal');

const options = reactive({
  mask: '+7 (###) ###-##-##',
  eager: false,
});

const nameRules = yup
  .string()
  .required('Обязательное поле')
  .min(2, 'Не меньше 2 символов');
const phoneRules = yup
  .string()
  .required('Обязательное поле')
  .min(18, 'Не меньше 11 цифр');
const emailRules = yup.string().email('Неверный формат');

const name = ref('');
const phone = ref('');
const email = ref('');
const city = ref('');

const isSuccessful = ref(false)

const telegram = {
    token: "6157134452:AAGBM39jETZEbWuMpnal_cwmf1ki0rftnGY",
    chat: "-909835224"
  }

const onSubmit = async (values, { resetForm }) => {
let msg = `<b>Заявка на франшизу</b>
<b>Имя</b>: ${values.name || values.twoName}
<b>Телефон</b>: ${values.phone || values.twoPhone}
<b>Email</b>: ${values.email || values.twoEmail || ''}
<b>Город</b>: ${values.city || ''}
`
  msg = encodeURI(msg);
  fetch(`https://api.telegram.org/bot${telegram.token}/sendMessage?chat_id=${telegram.chat}&parse_mode=html&text=${msg}`,{
    method:'POST'
  })
  .then(()=>{
    resetForm();
    isSuccessful.value = true;
    setTimeout(() => {
      close();
    }, 5000);
    setTimeout(() => {
      isSuccessful.value = false;
    }, 5500);
  })
};
</script>

<style scoped></style>
