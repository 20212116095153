<template>
  <component :is="icon" />
</template>

<script setup>
import { defineAsyncComponent,defineProps } from 'vue';

const props = defineProps({
  name: String
});

const icon = defineAsyncComponent(
  () => import(`@/components/icons/${props.name}.vue`)
);
</script>
