import { reactive, computed } from 'vue';

const modals = reactive([]);

export default function useModals() {
  const body = document.body;
  const isOpenModal = computed(() => {
    return !!modals.find((modal) => modal.isOpen === true);
  });

  const useModal = (name) => {
    if (!modals.includes({ name, isOpen: false })) {
      modals.push({ name, isOpen: false });
    }

    const index = computed(() => {
      return modals.findIndex((modal) => modal.name === name);
    });

    const isOpen = computed(() => {
      return modals[index.value].isOpen;
    });

    const close = () => {
      setTimeout(() => {
        body.classList.remove('overflow-hidden', 'pr-[15px]');
      }, 400);
      modals[index.value].isOpen = false;
    };

    const open = () => {
      body.classList.add('overflow-hidden', 'pr-[15px]');
      modals[index.value].isOpen = true;
    };

    return {
      open,
      close,
      isOpen,
    };
  };

  return { useModal, isOpenModal };
}
