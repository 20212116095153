<template>
  <div>
    <header class="flex justify-between items-center container py-9 pb-10">
      <a href="https://arbat.life/">
        <IconComponent
          name="LogoIcon"
          class="w-[113px] h-[31px] xl:w-[220px] xl:h-[60px] cursor-pointer"
        />
      </a>
      <span
        class="text-xl hidden lg:inline-flex p-3 cursor-pointer hover:text-ared"
        @click.prevent="scrollAnchor(company)"
        >О компании</span
      >
      <span
        class="text-xl hidden lg:inline-flex p-3 cursor-pointer hover:text-ared"
        @click.prevent="scrollAnchor(franchise)"
        >О франшизе</span
      >
      <span
        class="text-xl hidden lg:inline-flex p-3 cursor-pointer hover:text-ared"
        @click.prevent="scrollAnchor(sixSteps)"
        >6 шагов Арбат</span
      >
      <div
        class="hidden lg:flex items-center p-3 cursor-pointer ml-24 group gap-3"
        @click.prevent="scrollAnchor(contacts)"
      >
        <span class="text-xl inline-flex group-hover:text-ared">Контакты</span>
      </div>
    </header>
    <main>
      <section
        class="container flex justify-between pt-12 relative min-h-[358px] lg:min-h-[500px]"
      >
        <div class="flex flex-col justify-between gap-4 z-50">
          <div class="flex flex-col gap-4 xl:gap-14">
            <h1
              class="text-[28px] leading-[38px] lg:text-[64px] lg:leading-[88px] font-bold"
            >
              Франшиза<br />
              цифрового агентства<br />
              недвижимости
            </h1>
            <span class="text-base lg:text-[30px] lg:leading-[40px]"
              >Зарабатывайте больше вместе с нами</span
            >
          </div>
          <button class="button max-w-[360px] hover:bg-arblack" @click="open()">
            Оставить заявку
          </button>
        </div>
        <div
          class="absolute w-full right-0 top-3 xl:top-[-30px] xl:relative overflow-hidden max-w-[300px] lg:max-w-[550px] z-40"
        >
          <img
            class="relative right-[-70px] md:right-auto max-w-[300px] lg:max-w-[550px] max-h-[550px] py-8 animate-wiggle-rotate"
            src="@/assets/images/sphere.svg"
            alt=""
          />
        </div>
      </section>
      <section
        class="relative overflow-hidden xl:overflow-visible container py-8 lg:py-24"
      >
        <div
          class="overflow-x-scroll xl:overflow-visible flex xl:grid w-full xl:grid-cols-10 grid-rows-[auto_auto] gap-5 pb-3"
        >
          <div
            class="p-3 col-span-1 xl:col-span-2 min-w-[140px] bg-afrgray rounded-[10px] flex flex-col w-full xl:py-6 xl:px-5 gap-4 xl:gap-5"
          >
            <div>
              <span
                class="text-[28px] xl:text-[64px] leading-[38px] xl:leading-[88px] font-semibold pr-1"
                >0</span
              >
              <span class="text-sm xl:text-2xl leading-[54px] font-medium"
                >₽</span
              >
            </div>
            <span class="text-sm xl:text-2xl">Паушальный взнос</span>
          </div>
          <div
            class="p-3 col-span-1 xl:col-span-2 min-w-[140px] bg-afrgray rounded-[10px] flex flex-col w-full xl:py-6 xl:px-5 gap-4 xl:gap-5"
          >
            <div>
              <span
                class="text-[28px] xl:text-[64px] leading-[38px] xl:leading-[88px] font-semibold pr-1"
                >4</span
              >
              <span class="text-sm xl:text-2xl leading-[54px] font-medium"
                >%</span
              >
            </div>
            <span class="text-sm xl:text-2xl">Роялти от выручки</span>
          </div>
          <div
            class="p-3 col-span-1 xl:col-span-2 min-w-[140px] bg-afrgray rounded-[10px] flex flex-col w-full xl:py-6 xl:px-5 gap-4 xl:gap-5"
          >
            <div>
              <span class="text-sm xl:text-2xl leading-[54px] font-medium pr-1"
                >до</span
              >
              <span
                class="text-[28px] xl:text-[64px] leading-[38px] xl:leading-[88px] font-semibold pr-1"
                >990</span
              >
              <span class="text-sm xl:text-2xl leading-[54px] font-medium"
                >тыс</span
              >
            </div>
            <span class="text-sm xl:text-2xl">Стартовые инвестиции</span>
          </div>
          <div
            class="p-3 col-span-1 xl:col-span-2 min-w-[140px] bg-afrgray rounded-[10px] flex flex-col w-full xl:py-6 xl:px-5 gap-4 xl:gap-5"
          >
            <div>
              <span class="text-sm xl:text-2xl leading-[54px] font-medium pr-1"
                >от</span
              >
              <span
                class="text-[28px] xl:text-[64px] leading-[38px] xl:leading-[88px] font-semibold pr-1"
                >5</span
              >
              <span class="text-sm xl:text-2xl leading-[54px] font-medium"
                >месяцев</span
              >
            </div>
            <span class="text-sm xl:text-2xl">Безубыточность</span>
          </div>
          <div
            class="p-3 col-span-1 xl:col-span-2 min-w-[140px] bg-afrgray rounded-[10px] flex flex-col w-full xl:py-6 xl:px-5 gap-4 xl:gap-5"
          >
            <div>
              <span class="text-sm xl:text-2xl leading-[54px] font-medium pr-1"
                >от</span
              >
              <span
                class="text-[28px] xl:text-[64px] leading-[38px] xl:leading-[88px] font-semibold pr-1"
                >8</span
              >
              <span class="text-sm xl:text-2xl leading-[54px] font-medium"
                >месяцев</span
              >
            </div>
            <span class="text-sm xl:text-2xl">Окупаемость</span>
          </div>
          <div
            class="p-3 col-span-1 xl:col-span-5 min-w-[140px] flex flex-col xl:p-8 bg-white rounded-[10px] border-[1px] border-solid border-border2 gap-6"
          >
            <span class="text-xl xl:text-6xl font-semibold">Поддержка</span>
            <span class="text-sm xl:text-2xl">На всех этапах роста</span>
          </div>
          <div
            class="p-3 col-span-1 xl:col-span-5 min-w-[140px] flex flex-col xl:p-8 bg-white rounded-[10px] border-[1px] border-solid border-border2 gap-6"
          >
            <span class="text-xl xl:text-6xl font-semibold">Эксклюзив</span>
            <span class="text-sm xl:text-2xl">На город</span>
          </div>
        </div>
      </section>
      <section
        class="container flex flex-col gap-8 md:gap-28 py-8 lg:py-24"
        ref="company"
      >
        <h2 class="text-2xl md:text-[40px] leading-[60px] font-bold">
          Компания Арбат
        </h2>
        <div class="flex flex-col lg:flex-row lg:justify-between gap-6 w-full">
          <div class="flex gap-3 lg:gap-6">
            <span
              class="bg-ared rounded-full text-base text-white md:text-3xl font-medium flex justify-center items-center w-7 md:w-16 h-7 md:h-16 min-w-[28px] md:min-w-[64px]"
              >1</span
            >
            <div class="flex flex-col gap-4">
              <span class="text-base md:text-3xl font-bold pb-0 md:pb-8"
                >Арбат - это про людей</span
              >
              <span class="text-sm md:text-2xl"
                >- Система найма и адаптации</span
              >
              <span class="text-sm md:text-2xl">- Обучающий центр</span>
              <span class="text-sm md:text-2xl">- Онлайн-учебный портал</span>
            </div>
          </div>
          <div class="lg:max-w-[683px] w-full relative px-5 lg:px-0">
            <Swiper
              class="rounded-[10px] w-full lg:max-w-[683px]"
              :modules="[Navigation, Autoplay]"
              :centered-slides="true"
              :slides-per-view="1"
              :autoplay="{
                delay: 4000,
                disableOnInteraction: true,
              }"
              :navigation="{
                prevEl: prevElCompany,
                nextEl: nextElCompany,
                enabled: true,
              }"
              :loop="true"
            >
              <SwiperSlide class="relative">
                <img class="w-full" src="@/assets/images/franchise-1.png" alt="" />
              </SwiperSlide>
              <SwiperSlide class="relative">
                <img class="w-full" src="@/assets/images/franchise-2.png" alt="" />
              </SwiperSlide>
              <SwiperSlide class="relative">
                <img class="w-full" src="@/assets/images/franchise-3.png" alt="" />
              </SwiperSlide>
            </Swiper>
            <div
              class="items-center justify-between left-0 lg:left-auto gap-8 flex absolute top-[calc(50%_-_15px)] w-full lg:w-auto lg:top-auto lg:bottom-5 lg:right-5 z-[100]"
            >
              <div
                ref="prevElCompany"
                class="rounded-full select-none cursor-pointer right-0 bg-white flex justify-center items-center border-solid border-[1px] border-borderarrow lg:border-none h-[30px] w-[30px] md:h-10 md:w-10"
              >
                <IconComponent
                  name="ArrowLeftIcon"
                  class="w-4 h-4 md:w-6 md:h-6 fill-ablack"
                />
              </div>
              <div
                ref="nextElCompany"
                class="rounded-full select-none cursor-pointer right-0 bg-white flex justify-center items-center border-solid border-[1px] border-borderarrow lg:border-none h-[30px] w-[30px] md:h-10 md:w-10"
              >
                <IconComponent
                  name="ArrowRightIcon"
                  class="w-4 h-4 md:w-6 md:h-6 fill-ablack"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col lg:flex-row lg:justify-between gap-6 w-full">
          <div class="flex gap-3 lg:gap-6">
            <span
              class="bg-ared rounded-full text-base text-white md:text-3xl font-medium flex justify-center items-center w-7 md:w-16 h-7 md:h-16 min-w-[28px] md:min-w-[64px]"
              >2</span
            >
            <div class="flex flex-col gap-4">
              <span class="text-base md:text-3xl font-bold pb-0 md:pb-8"
                >Мы аккредитованная IT-компания</span
              >
              <span class="text-sm md:text-2xl">- Собственная CRM-система</span>
              <span class="text-sm md:text-2xl"
                >- Онлайн-сервис страхования</span
              >
            </div>
          </div>
          <div
            class="rounded-[10px] lg:max-w-[683px] flex justify-center w-full px-5 lg:px-0"
          >
            <img
              class="rounded-[10px] max-w-[683px] w-full"
              src="@/assets/images/franchise-4.png"
              alt=""
            />
          </div>
        </div>
        <div class="flex flex-col lg:justify-between gap-6 w-full">
          <div class="flex items-center gap-3 lg:gap-6">
            <span
              class="bg-ared rounded-full text-base text-white md:text-3xl font-medium flex justify-center items-center w-7 md:w-16 h-7 md:h-16 min-w-[28px] md:min-w-[64px]"
            >
              3
            </span>

            <span class="text-base md:text-3xl font-bold"
              >Рынок недвижимости доступный всем</span
            >
          </div>
          <video
            preload="none"
            poster="@/assets/images/poster.jpg"
            class="w-full col-span-2"
            src="@/assets/video/education.mp4"
            controls
          ></video>
        </div>
      </section>
      <section class="container py-8 lg:py-24" ref="franchise">
        <h2
          class="text-2xl lg:text-[40px] lg:leading-[60px] font-bold pb-7 md:pb-16"
        >
          Франшиза Арбат
        </h2>
        <div class="relative">
          <Swiper
            class="rounded-[10px]"
            :modules="[Navigation, Autoplay]"
            :centered-slides="true"
            :slides-per-view="1"
            :autoplay="{
              delay: 4000,
              disableOnInteraction: true,
            }"
            :loop="true"
            :navigation="{
              prevEl: prevElFranch,
              nextEl: nextElFranch,
              enabled: true,
            }"
          >
            <SwiperSlide class="relative">
              <div
                class="w-full flex flex-col lg:grid lg:grid-cols-[664px_auto] bg-afrgray"
              >
                <div
                  class="flex flex-col px-4 py-2 lg:py-14 lg:pl-20 lg:pr-5 gap-3 md:gap-4 justify-between h-auto min-h-[400px] s:min-h-[300px] lg:h-[664px]"
                >
                  <span class="text-base md:text-3xl font-bold pb-1 lg:pb-5"
                    >Служба управления персоналом</span
                  >
                  <span class="text-sm md:text-2xl"
                    >- Предоставляем сервис по найму: поиск и обучение hr,
                    курирование на каждом этапе;</span
                  >
                  <span class="text-sm md:text-2xl"
                    >- Обучающий портал в виде онлайн-уроков и тестированию по
                    каждому блоку;</span
                  >
                  <span class="text-sm md:text-2xl"
                    >- Личный наставник, который отвечает за практическое
                    обучение;</span
                  >
                  <span class="text-sm md:text-2xl"
                    >- Инструменты для мотивации, адаптации, оценки и развития
                    персонала.</span
                  >
                </div>
                <div
                  class="md:h-full w-full relative px-4 py-3 lg:p-0 rounded-lg lg:rounded-none"
                >
                  <div
                    class="hidden lg:block absolute top-0 left-0 to-transparent bg-gradient-to-r from-afrgray w-full h-full"
                  ></div>
                  <img
                    src="@/assets/images/franch-1.jpg"
                    class="h-full w-full object-cover rounded-lg lg:rounded-none"
                    alt=""
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide class="relative">
              <div
                class="w-full flex flex-col lg:grid lg:grid-cols-[664px_auto] bg-afrgray"
              >
                <div
                  class="flex flex-col px-4 py-2 lg:py-14 lg:pl-20 lg:pr-5 gap-3 md:gap-4 justify-between h-auto min-h-[400px] s:min-h-[300px] lg:h-[664px]"
                >
                  <span class="text-base md:text-3xl font-bold pb-1 lg:pb-5"
                    >Мощная поддержка бэкофиса</span
                  >
                  <span class="text-sm md:text-2xl"
                    >- Поддержка всех партнеров и достижение поставленных
                    целей;</span
                  >
                  <span class="text-sm md:text-2xl"
                    >- Анализ текущей ситуации и выявление проблем;</span
                  >
                  <span class="text-sm md:text-2xl"
                    >- Помощь в реорганизации структурных подразделений
                    бизнеса;</span
                  >
                  <span class="text-sm md:text-2xl"
                    >- Консультирование по широкому кругу вопросов в финансовой,
                    коммерческой, юридической, экспертной областей.</span
                  >
                </div>
                <div
                  class="md:h-full w-full relative px-4 py-3 lg:p-0 rounded-lg lg:rounded-none"
                >
                  <div
                    class="hidden lg:block absolute top-0 left-0 to-transparent bg-gradient-to-r from-afrgray w-full h-full"
                  ></div>
                  <img
                    src="@/assets/images/franch-2.jpg"
                    class="h-full w-full object-cover rounded-lg lg:rounded-none"
                    alt=""
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide class="relative">
              <div
                class="w-full flex flex-col lg:grid lg:grid-cols-[664px_auto] bg-afrgray"
              >
                <div
                  class="flex flex-col px-4 py-2 lg:py-14 lg:pl-20 lg:pr-5 gap-3 md:gap-4 h-auto min-h-[400px] s:min-h-[300px] lg:h-[664px]"
                >
                  <span class="text-base md:text-3xl font-bold pb-1 lg:pb-5"
                    >Юридическая служба</span
                  >
                  <span class="text-sm md:text-2xl"
                    >- Онлайн-консультации и шаблоны готовых решений по
                    сделкам;</span
                  >
                  <span class="text-sm md:text-2xl"
                    >- Помощь в разрешении споров;</span
                  >
                  <span class="text-sm md:text-2xl"
                    >- Юридическая подготовка и обучение специалистов.</span
                  >
                </div>
                <div
                  class="md:h-full w-full relative px-4 py-3 lg:p-0 rounded-lg lg:rounded-none"
                >
                  <div
                    class="hidden lg:block absolute top-0 left-0 to-transparent bg-gradient-to-r from-afrgray w-full h-full"
                  ></div>
                  <img
                    src="@/assets/images/franch-3.jpg"
                    class="h-full w-full object-cover rounded-lg lg:rounded-none"
                    alt=""
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide class="relative">
              <div
                class="w-full flex flex-col lg:grid lg:grid-cols-[664px_auto] bg-afrgray"
              >
                <div
                  class="flex flex-col px-4 py-2 lg:py-14 lg:pl-20 lg:pr-5 gap-3 md:gap-4 justify-between h-auto min-h-[400px] s:min-h-[300px] lg:h-[664px]"
                >
                  <span class="text-base md:text-3xl font-bold pb-1 lg:pb-5"
                    >Уникальная CRM-система</span
                  >
                  <span class="text-sm md:text-2xl"
                    >- Оптимизирована система требований к рекламным площадкам,
                    благодаря которой исключены блокировки;</span
                  >
                  <span class="text-sm md:text-2xl"
                    >- В CRM-системе включены алгоритмы, которые автоматизируют
                    работу всех сотрудников компании;</span
                  >
                  <span class="text-sm md:text-2xl"
                    >- Ипотечный блок и оценка недвижимости;</span
                  >
                  <span class="text-sm md:text-2xl"
                    >- Юридический блок с готовыми проектами договоров;</span
                  >
                  <span class="text-sm md:text-2xl"
                    >- Автоматический подбор объектов по заявкам
                    покупателей.</span
                  >
                </div>
                <div
                  class="md:h-full w-full relative px-4 py-3 lg:p-0 rounded-lg lg:rounded-none"
                >
                  <div
                    class="hidden lg:block absolute top-0 left-0 to-transparent bg-gradient-to-r from-afrgray w-full h-full"
                  ></div>
                  <img
                    src="@/assets/images/franch-4.jpg"
                    class="h-full w-full object-cover rounded-lg lg:rounded-none"
                    alt=""
                  />
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          <div
            class="hidden items-center justify-between left-6 xl:left-[-24px] gap-8 afterlaptop:flex absolute top-[calc(50%_-_24px)] w-[calc(100%_-_48px)] xl:w-[calc(100%_+_48px)] z-[100]"
          >
            <div
              ref="prevElFranch"
              class="rounded-full select-none cursor-pointer bg-white flex justify-center items-center border-solid border-[1px] border-borderarrow h-12 md:w-12"
            >
              <IconComponent
                name="ArrowLeftIcon"
                class="w-4 h-4 md:w-6 md:h-6 fill-ablack"
              />
            </div>
            <div
              ref="nextElFranch"
              class="rounded-full select-none cursor-pointer bg-white flex justify-center items-center border-solid border-[1px] border-borderarrow h-12 md:w-12"
            >
              <IconComponent
                name="ArrowRightIcon"
                class="w-4 h-4 md:w-6 md:h-6 fill-ablack"
              />
            </div>
          </div>
        </div>
      </section>
      <section class="container relative flex flex-col py-8 lg:py-24">
        <div
          class="flex flex-col lg:flex-row justify-between bg-white lg:bg-afrgray rounded-[15px] w-full p-0 lg:py-12 lg:px-14 gap-5"
        >
          <div class="flex flex-col">
            <span class="text-2xl lg:text-3xl font-bold mb-8 max-w-[280px]"
              >Станьте партнером Арбат</span
            >
            <span class="text-sm lg:text-2xl mb-14 max-w-[511px]"
              >Оставьте заявку или позвоните по телефону, и мы предоставим Вам
              полную информацию о франшизе и проконсультируем по всем
              вопросам.</span
            >
            <div
              class="flex bg-afrgray rounded-[10px] p-4 max-w-[398px] lg:max-w-full"
            >
              <img
                src="@/assets/images/manager.png"
                class="w-[50px] h-[50px] s:w-[112px] s:h-[112px] mr-4"
                alt=""
              />
              <div class="flex flex-col gap-2">
                <span class="text-base lg:text-2xl font-bold"
                  >Ольга Петрова</span
                >
                <span class="text-xs lg:text-xl"
                  >Менеджер по продаже франшизы</span
                >
                <a href="tel:+74942302828" class="flex items-center gap-2">
                  <IconComponent name="PhoneIcon" class="w-4 lg:w-7 h-4 lg:h-7" />
                  <span class="text-base lg:text-2xl font-bold"
                    >+7 (4942) 30-28-28</span
                  >
                </a>
              </div>
            </div>
          </div>
          <Form
            v-slot="{ errors }"
            class="partners flex flex-col max-w-[398px] gap-6"
            @submit="onSubmit"
            v-show="!isSuccessful"
          >
            <div class="flex relative">
              <Field
                name="name"
                class="input-auth lg:!h-14 w-full lg:px-5 lg:py-4"
                :rules="nameRules"
                placeholder="Имя*"
                v-model="name"
                :class="{ 'input-error': errors.name }"
              />
              <ErrorMessage
                name="name"
                class="absolute top-[100%] pt-1 text-ared text-xs"
              />
            </div>
            <div class="flex relative">
              <Field
                name="phone"
                class="input-auth lg:!h-14 w-full"
                :rules="phoneRules"
                type="text"
                placeholder="+7 (___) ___-__-__*"
                v-maska:[options]
                v-model="phone"
                :class="{ 'input-error': errors.phone }"
              />
              <ErrorMessage
                name="phone"
                class="absolute top-[100%] pt-1 text-ared text-xs"
              />
            </div>
            <div class="flex relative">
              <Field
                name="email"
                class="input-auth lg:!h-14 w-full"
                :rules="emailRules"
                placeholder="E-mail"
                v-model="email"
                :class="{ 'input-error': errors.email }"
              />
              <ErrorMessage
                name="email"
                class="absolute top-[100%] pt-1 text-ared text-xs"
              />
            </div>

            <button class="button mt-2">Отправить</button>
            <span
              >Отправляя заявку Вы соглашаетесь на
              <a
                class="underline"
                href="https://arbat.life/doc/document.pdf"
                target="_blank"
                >обработку персональных данных</a
              ></span
            >
          </Form>
          <div class="flex flex-col gap-3 md:gap-5 max-w-[398px]" v-if="isSuccessful">
            <span class="text-base md:text-3xl font-bold">Спасибо! Ваша заявка принята</span>
            <span class="text-sm md:text-2xl ">В ближайшее время с Вами свяжется наш менеджер по продаже франшизы.</span>
          </div>
        </div>
      </section>
      <section class="bg-country my-8 lg:my-24" ref="sixSteps">
        <div class="container py-12">
          <h2
            class="text-2xl lg:text-[40px] lg:leading-[60px] font-bold mb-7 md:mb-12"
          >
            6 шагов Арбат
          </h2>
          <div
            class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 grid-rows-2 gap-3 md:gap-5"
          >
            <div
              class="grid grid-cols-[23px_auto] md:grid-cols-[52px_auto] grid-rows-2 bg-afrgray md:bg-white rounded-[10px] md:rounded-[15px] p-6 md:min-h-[281px] gap-3 md:gap-0"
            >
              <span
                class="bg-ared rounded-full text-white text-sm md:text-3xl font-medium flex justify-center items-center w-[23px] h-[23px] md:w-[52px] md:h-[52px]"
                >1</span
              >
              <span class="text-base md:text-3xl font-bold md:pl-6"
                >Подача заявки</span
              >

              <span class="text-sm md:text-2xl col-span-2"
                >Вы оставляете заявку на сайте или по телефону.</span
              >
            </div>

            <div
              class="grid grid-cols-[23px_auto] md:grid-cols-[52px_auto] grid-rows-2 bg-afrgray md:bg-white rounded-[10px] md:rounded-[15px] p-6 md:min-h-[281px] gap-3 md:gap-0"
            >
              <span
                class="bg-ared rounded-full text-white text-sm md:text-3xl font-medium flex justify-center items-center w-[23px] h-[23px] md:w-[52px] md:h-[52px]"
                >2</span
              >
              <span class="text-base md:text-3xl font-bold md:pl-6"
                >Презентация</span
              >

              <span class="text-sm md:text-2xl col-span-2"
                >Мы подробно расскажем обо всех преимуществах нашего
                партнерства.</span
              >
            </div>
            <div
              class="grid grid-cols-[23px_auto] md:grid-cols-[52px_auto] grid-rows-2 bg-afrgray md:bg-white rounded-[10px] md:rounded-[15px] p-6 md:min-h-[281px] gap-3 md:gap-0"
            >
              <span
                class="bg-ared rounded-full text-white text-sm md:text-3xl font-medium flex justify-center items-center w-[23px] h-[23px] md:w-[52px] md:h-[52px]"
                >3</span
              >
              <span class="text-base md:text-3xl font-bold md:pl-6"
                >Стартовый расчет инвестиций</span
              >

              <span class="text-sm md:text-2xl col-span-2"
                >Индивидуальный бизнес-план.</span
              >
            </div>
            <div
              class="grid grid-cols-[23px_auto] md:grid-cols-[52px_auto] grid-rows-2 bg-afrgray md:bg-white rounded-[10px] md:rounded-[15px] p-6 md:min-h-[281px] gap-3 md:gap-0"
            >
              <span
                class="bg-ared rounded-full text-white text-sm md:text-3xl font-medium flex justify-center items-center w-[23px] h-[23px] md:w-[52px] md:h-[52px]"
                >4</span
              >
              <span class="text-base md:text-3xl font-bold md:pl-6"
                >Подписание договора</span
              >

              <span class="text-sm md:text-2xl col-span-2"
                >Подписываем договор о сотрудничестве.</span
              >
            </div>
            <div
              class="grid grid-cols-[23px_auto] md:grid-cols-[52px_auto] grid-rows-2 bg-afrgray md:bg-white rounded-[10px] md:rounded-[15px] p-6 md:min-h-[281px] gap-3 md:gap-0"
            >
              <span
                class="bg-ared rounded-full text-white text-sm md:text-3xl font-medium flex justify-center items-center w-[23px] h-[23px] md:w-[52px] md:h-[52px]"
                >5</span
              >
              <span class="text-base md:text-3xl font-bold md:pl-6"
                >Маркетинговый план</span
              >

              <span class="text-sm md:text-2xl col-span-2"
                >Внедрение системы и запуск Вашего бизнеса.</span
              >
            </div>
            <div
              class="grid grid-cols-[23px_auto] md:grid-cols-[52px_auto] grid-rows-2 bg-afrgray md:bg-white rounded-[10px] md:rounded-[15px] p-6 md:min-h-[281px] gap-3 md:gap-0"
            >
              <span
                class="bg-ared rounded-full text-white text-sm md:text-3xl font-medium flex justify-center items-center w-[23px] h-[23px] md:w-[52px] md:h-[52px]"
                >6</span
              >
              <span class="text-base md:text-3xl font-bold md:pl-6"
                >Развитие бизнеса</span
              >

              <span class="text-sm md:text-2xl col-span-2"
                >Поддержка на всех этапах роста и развитие бизнеса через
                инновации.</span
              >
            </div>
          </div>
        </div>
      </section>
      <section class="container flex flex-col py-8 lg:py-24">
        <h2 class="text-2xl lg:text-[40px] leading-[60px] font-bold">
          Нам доверяют
        </h2>
        <div
          class="flex flex-col md:grid md:grid-rows-[auto_auto] lg:grid-rows-2 grid-flow-col gap-5 justify-between items-start md:items-center pb-7"
        >
          <h2
            class="text-base md:text-3xl font-bold col-span-2 pr-0 lg:pr-48 pt-0 lg:pt-20"
          >
            Агентство недвижимости “Арбат” - лучшая региональная риэлторская
            организация на вторичном рынке жилья численностью до 100 человек.
          </h2>
          <p class="text-sm md:text-xl lg:col-span-2 pr-0 md:pr-48">
            По итогам национального конкурса в сфере недвижимости, строительства
            и ипотечного страхования CREDO-2022.
          </p>
          <img
            class="pr-0 lg:pr-[80px] lg:row-span-3 max-w-[100px] s:max-w-[150px] md:!max-w-[100%] justify-self-center"
            src="@/assets/images/credo.png"
            alt="credo"
          />
        </div>
        <div class="flex gap-1 md:gap-6 pb-7">
          <div
            class="bg-ared rounded-full min-w-[20px] min-h-[20px] w-5 h-5 m-[10px]"
          ></div>
          <div class="flex flex-col">
            <span class="text-base md:text-3xl font-semibold pb-2"
              >Домклик. СБЕР</span
            >
            <span class="text-sm md:text-2xl pb-3">Золотой партнер</span>
            <span class="text-xs md:text-xl">2022 г.</span>
          </div>
        </div>
        <div class="flex gap-1 md:gap-6">
          <div
            class="bg-ared rounded-full w-5 h-5 min-w-[20px] min-h-[20px] m-[10px]"
          ></div>
          <div class="flex flex-col">
            <span class="text-base md:text-3xl font-semibold pb-2"
              >РоссельхозБанк</span
            >
            <span class="text-sm md:text-2xl pb-3"
              >Лидер по реализации в Костромской области программы “Сельская
              ипотека в 2022 году”</span
            >
            <span class="text-xs md:text-xl">2022 г.</span>
          </div>
        </div>
      </section>
      <section class="container py-8 lg:pt-24 lg:pb-48">
        <div
          class="flex flex-col md:grid md:grid-cols-[250px_auto] lg:grid-cols-[400px_auto] md:grid-rows-[auto_auto_auto] bg-afrgray rounded-[10px] p-5 gap-3 md:gap-8"
        >
          <span
            class="text-base font-bold order-1 md:order-2 lg:text-3xl xl:pl-4"
            >Выводим на новый уровень качество услуг</span
          >
          <span class="text-sm lg:text-2xl pb-2 order-2 md:order-3 xl:pl-4"
            >Я, Наталья Макарова, Директор компании АРБАТ. Мы развиваемся в
            сфере недвижимости и создаем компанию нового времени — объединяем
            технологии и уважительное отношение к каждому партнеру. Инвестируя в
            обучение и автоматизацию процессов выводим на новый уровень качество
            услуг. Успешный партнер и счастливый клиент —это то, к чему мы
            стремимся всегда!</span
          >
          <img
            class="rounded-xl order-3 md:order-1 md:row-span-3"
            src="@/assets/images/director.png"
            alt="Макарова Наталья Александровна"
          />
          <span class="text-sm lg:text-xl italic pt-1 order-4 xl:pl-4"
            >Директор ООО “Арбат” Макарова Наталья Александровна</span
          >
        </div>
      </section>
    </main>
    <footer class="footer-franchise" ref="contacts">
      <section class="mb-6 lg:py-12 lg:mb-0">
        <div class="container">
          <div class="flex items-center justify-between">
            <IconComponent name="LogoIcon" class="w-[220px] h-[60px]" />
            <span class="text-arbgray text-xs lg:text-base">
              2022 Компания “Арбат”. Все права защищены.<br />
              При использовании материалов активная ссылка на источник
              обязательна.
            </span>
          </div>
        </div>
      </section>
      <div
        class="mb-6 lg:border-border2 lg:border-t lg:border-b lg:border-solid lg:py-6 lg:mb-0"
      >
        <div class="container">
          <div class="lg:flex">
            <div class="w-full flex flex-col justify-between gap-6">
              <div class="w-full flex flex-col gap-6">
                <a
                  href="tel:+74942302828"
                  class="flex items-center gap-[10px] hover:text-ared w-fit"
                >
                  <IconComponent name="PhoneIcon" class="w-5 h-5" />
                  <span class="font-medium">+ 7 (4942) 30-28-28</span>
                </a>
                <div class="flex items-center gap-[10px]">
                  <IconComponent name="MapMarker" class="w-5 h-5" />

                  <span class="font-medium">Кострома, ул. Никитская, д.35</span>
                </div>
                <a
                  href="mailto:petrova.o@arbat.life"
                  class="font-medium hover:text-ared w-fit"
                  >E-mail: petrova.o@arbat.life</a
                >
                <div class="flex flex-col">
                  <span class="font-medium">Мы в соцсетях:</span>
                  <div class="flex items-center gap-[10px] lg:gap-5">
                    <a href="https://vk.com/club184855141" target="_blank">
                      <IconComponent name="VkIcon" class="w-7 h-7" />
                    </a>
                    <a
                      href="https://api.whatsapp.com/send/?phone=79109560061&text&type=phone_number&app_absent=0"
                      target="_blank"
                    >
                      <IconComponent name="WaIcon" class="w-[22px] h-[22px]" />
                    </a>
                  </div>
                </div>
              </div>
              <a class="flex w-fit" href="https://arbat.life/">
                <div
                  class="text-xl font-semibold text-white px-6 py-[6px] bg-ared rounded-[10px] w-fit"
                >
                  arbat.life
                </div>
              </a>
            </div>
            <Form
              v-slot="{ errors }"
              class="hidden bg-afrgray rounded-[15px] px-9 py-6 lg:flex flex-col gap-4 w-full max-w-[602px]"
              @submit="onSubmit"
              v-show="!isSuccessful"
            >
              <span class="text-2xl font-bold">Остались вопросы?</span>
              <span class="text-base"
                >Оставьте заявку для более подробной консультации</span
              >
              <div class="grid grid-cols-2 gap-6 pb-2">
                <div class="flex relative">
                  <Field
                    name="twoName"
                    class="input-auth lg:!h-14 w-full lg:px-5 lg:py-4"
                    :rules="nameRules"
                    placeholder="Имя*"
                    v-model="twoName"
                    :class="{ 'input-error': errors.twoName }"
                  />
                  <ErrorMessage
                    name="twoName"
                    class="absolute top-[100%] pt-1 text-ared text-xs"
                  />
                </div>
                <div class="flex relative">
                  <Field
                    name="twoPhone"
                    class="input-auth lg:!h-14 w-full"
                    :rules="phoneRules"
                    placeholder="+7 (___) ___-__-__*"
                    v-maska:[options]
                    v-model="twoPhone"
                    :class="{ 'input-error': errors.twoPhone }"
                  />
                  <ErrorMessage
                    name="twoPhone"
                    class="absolute top-[100%] pt-1 text-ared text-xs"
                  />
                </div>
              </div>
              <div class="grid grid-cols-2 gap-6 pb-2">
                <div class="flex relative">
                  <Field
                    name="twoEmail"
                    class="input-auth lg:!h-14 w-full"
                    :rules="emailRules"
                    placeholder="E-mail"
                    v-model="twoEmail"
                    :class="{ 'input-error': errors.twoEmail }"
                  />
                  <ErrorMessage
                    name="twoEmail"
                    class="absolute top-[100%] pt-1 text-ared text-xs"
                  />
                </div>
                <button class="button !h-14">Отправить</button>
              </div>
              <span
                >Отправляя заявку Вы соглашаетесь на
                <a
                  class="underline"
                  href="https://arbat.life/doc/document.pdf"
                  target="_blank"
                  >обработку персональных данных</a
                ></span
              >
            </Form>
            <div class="flex flex-col gap-3 md:gap-5 max-w-[602px]" v-if="isSuccessful">
              <span class="text-base md:text-3xl font-bold">Спасибо! Ваша заявка принята</span>
              <span class="text-sm md:text-2xl ">В ближайшее время с Вами свяжется наш менеджер по продаже франшизы.</span>
            </div>
          </div>
        </div>
      </div>
      <div class="lg:py-6">
        <div class="container">
          <div class="flex flex-col">
            <span
              class="font-medium text-sm mb-[6px] lg:text-base lg:mb-[11px]"
            >
              Политика конфиденциальности
            </span>
            <span
              class="text-arbgray max-w-[715px] text-[8px] leading-[11px] lg:text-sm"
            >
              Вся информация на сайте носит исключительно справочный характер и
              ни при каких условиях не является публичной офертой, определяемой
              положениями Статьи 437 ГКРФ
            </span>
          </div>
        </div>
      </div>
    </footer>
    <FranchiseModal />
  </div>
</template>

<script setup >
import { ref, reactive } from 'vue';
import useModals from '@/composables/useModals.js';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import { Navigation, Autoplay } from 'swiper';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { vMaska } from 'maska';
import IconComponent from '@/components/IconComponent.vue';
import "@/assets/css/tailwind.css";
import FranchiseModal from "@/components/FranchiseModal.vue";

const { useModal } = useModals();
const { open } = useModal('callBackFranchiseModal');

const options = reactive({
  mask: '+7 (###) ###-##-##',
  eager: false,
});

const prevElCompany = ref(null);
const nextElCompany = ref(null);
const prevElFranch = ref(null);
const nextElFranch = ref(null);

const company = ref();
const franchise = ref();
const sixSteps = ref();
const contacts = ref();

const nameRules = yup
  .string()
  .required('Обязательное поле')
  .min(2, 'Не меньше 2 символов');
const phoneRules = yup
  .string()
  .required('Обязательное поле')
  .min(18, 'Не меньше 11 цифр');
const emailRules = yup.string().email('Неверный формат');
const name = ref('');
const phone = ref('');
const email = ref('');
const twoName = ref('');
const twoPhone = ref('');
const twoEmail = ref('');


const scrollAnchor = (refId) => {
  refId?.scrollIntoView({ behavior: 'smooth' });
};

const isSuccessful = ref(false);

const telegram = {
    token: "6157134452:AAGBM39jETZEbWuMpnal_cwmf1ki0rftnGY",
    chat: "-909835224"
  }

const onSubmit = (values, { resetForm }) => {
let msg = `<b>Заявка на франшизу</b>
<b>Имя</b>: ${values.name || values.twoName}
<b>Телефон</b>: ${values.phone || values.twoPhone}
<b>Email</b>: ${values.email || values.twoEmail || ''}
<b>Город</b>: ${values.city || ''}
`
  msg = encodeURI(msg);
  fetch(`https://api.telegram.org/bot${telegram.token}/sendMessage?chat_id=${telegram.chat}&parse_mode=html&text=${msg}`,{
    method:'POST'
  })
  .then(()=>{
    resetForm();
    isSuccessful.value = true;
    setTimeout(() => {
      isSuccessful.value = false;
    }, 5000);
  })
};
</script>

<style scoped>

@-webkit-keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}
.animate-wiggle-rotate {
  -webkit-animation: wiggle 30s linear infinite;
  animation: wiggle 30s linear infinite;
}

.footer-franchise {
  background-image: url('/public/images/footer-franch.png');
  background-repeat: no-repeat;
}
.bg-country {
  background-image: none;
}
@media screen and (min-width: 768px) {
  .bg-country {
    background-image: url('/public/images/bg-russia.png');
  }
}



</style>
